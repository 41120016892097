<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                非凸智能算法正式上线银河证券启明 iTrade
              </p>
              <div class="fst-italic mb-2">2022年9月16日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >银河证券</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/14/01-封面.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    金融与算法的深度融合，已成为行业发展新趋势。此次，非凸智能算法上线中国银河证券，旨在为机构客户提供优质、高效的智能算法服务，最大限度降低交易成本，进而突破价格最优水平。同时，基于行业领先的技术创新，非凸科技将通过高性能交易、高效率风控、高质量服务共同助力算法交易。
                  </p>
                   <p>
                    非凸智能算法在传统算法交易的基础上，融入机器学习、深度学习等人工智能技术，进行因子挖掘和模型训练，并通过 Rust 架构保证交易系统的极速和稳定，依靠领先的长周期预测能力和积极主动的交易策略，算法交易的绩效能战胜 Twap/Vwap 基准 3-5BP，显著领先市场。
                  </p>
                  <p>
                    非凸科技拥有强大的金融和计算机工程团队，可以针对不同客户的差异化需求，定制专属的功能模块，以助力中国银河证券推进智能算法服务向“广度”拓宽，向“深度”挖掘，从而提升算法赋能机构客户的效力。
                  </p>
                  <p>
                    随着国内市场不断走向成熟，数字化、智能化正成为主流，算法交易的优势也逐渐凸显，并成为机构投资者更为关注的部分。非凸科技也将持续为券商机构提供与业务深度结合、更具功能特色的全方位技术解决方案，让算法交易业务实现快速发展。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/14/02-银河证券.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    欢迎申请试用：可直接咨询中国银河证券营业部，或联系非凸科技商务部咨询定制化算法合作。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News13",
};
</script>

<style></style>
